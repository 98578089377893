import React from 'react';
import PropTypes from 'prop-types';

import styles from './contentWrapper.module.scss';

const ContentWrapper = ({ children, isPageWrapper }) => {
  const sectionClass = isPageWrapper
    ? styles.pageWrapper
    : styles.sectionWrapper;

  return (
    <section className={sectionClass}>
      <div className={styles.content}>{children}</div>
    </section>
  );
};

ContentWrapper.defaultProps = {
  children: null,
  isPageWrapper: false,
};

ContentWrapper.propTypes = {
  children: PropTypes.node,
  isPageWrapper: PropTypes.bool,
};

export default ContentWrapper;

import React from 'react';

import ContentWrapper from '../components/contentWrapper/contentWrapper';

const NotFoundPage = () => (
  <ContentWrapper>
    <p>You just hit a route that doesn&#39;t exist.</p>
  </ContentWrapper>
);

export default NotFoundPage;
